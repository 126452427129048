<template>
  <div class="flex flex-col h-screen">
    <NavbarComponent />
    <main class="mt-20">
      <!-- <section class="">
        <div class="flex flex-col">
          <div class="">p
            <img src="./../../assets/golfigo/vegi-view.png" alt="" />
          </div>
          <div class="p-5 bg-theme-sidebar -mt-6 md:-mt-40 z-50">
            <div class="grid grid-cols-1 md:grid-cols-2 text-white">
              <div class="flex flex-col">
                <div v-for="(item, i) in screenshots" :key="i">
                  <p
                    class="text-xl mb-2"
                    v-for="row in item.swe_description"
                    :key="row"
                  >
                    <span class="text-2xl">{{ row }}</span>
                  </p>
                </div>
               
              </div>
              <div class="flex flex-col items-center">
                <div class="flex flex-col w-full rounded-md p-1 bg-gray-200">
                  <div>
                    <b-carousel
                      ref="myCarousel"
                      img-width="1024"
                      :interval="6000"
                    >
                      <b-carousel-slide
                        v-for="(item, i) in screenshots"
                        :key="i"
                      >
                        <template #img>
                          <img
                            class="d-block img-fluid w-100"
                            width="100%"
                            height="380"
                            :src="item.image"
                            alt="image slot"
                          />
                        </template>
                      </b-carousel-slide>
                    </b-carousel>
                  </div>
                </div>
                <div class="flex justify-center mt-2">
                  <div class="flex gap-4">
                    <span class="cursor-pointer" @click="prev">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-10 w-10 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                        />
                      </svg>
                    </span>
                    <span class="cursor-pointer" @click="next">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-10 w-10 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <section class="mb-2">
        <div class="flex flex-col bg-gray-200">
          <h2 class="text-2xl font-semibold text-center support-gradient mt-20">
            Integrerade ekonomiprogram
          </h2>
          <div
            class="grid grid-cols-1 md:grid-cols-3 gap-10 flex justify-center mx-auto w-3/4 mt-2 mb-10"
          >
            <div class="bg-white rounded-md shadow-md p-1 h-40">
              <div class="w-100 h-20 block">
                <img class="" src="./../../assets/golfigo/fortnox.png" alt="" />
              </div>
            </div>
            <div class="bg-white rounded-md shadow-md p-1 h-40">
              <div class="w-100 h-20 block">
                <img class="" src="./../../assets/golfigo/visma.png" alt="" />
              </div>
            </div>
            <div class="bg-white rounded-md shadow-md p-1 h-40">
              <div class="w-100 h-20 block">
                <img
                  class=""
                  src="./../../assets/golfigo/visma-admin.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col mt-3 bg-white" id="price-section">
            <h2 class="mb-1 mt-4 font-semibold text-green-500 text-3xl text-center">{{ $t('Home.packageCaption') }}</h2>
            <!-- <h2 style="padding-left: 320px; padding-right: 320px;" class="mb-1 mt-4 font-thin text-xl text-center">{{ $t('Home.launchInfo') }}</h2> -->
            <div class="mt-2 mb-2 flex flex-col md:flex-row gap-4 p-1">
              <div class="form-group md:w-1/4">
                <select
                  @change="onChangeAccountingProgram"
                  v-model="subForm.accounting_programs"
                  class="w-full bg-gray-100 rounded-md p-1 custom-border border-blue-200 focus:outline-none focus:border-blue-300 text-gray-600 text-2xl"
                >
                  <option value="" selected disabled>
                    Välj ekonomiprogram
                  </option>
                  <option
                    :value="item"
                    v-for="(item, i) in subscriptions.accounting_programs"
                    :key="i"
                  >
                    {{ item.swe_name }}
                  </option>
                </select>
              </div>
              <!-- <div class="form-group md:w-1/4">
                <select
                  @change="onChangePaymentplan"
                  v-model="subForm.payment_plans"
                  class="w-full bg-gray-100 rounded-md p-1 custom-border border-blue-200 focus:outline-none focus:border-blue-300 text-gray-600 text-2xl"
                >
                  <option value="" selected disabled>
                    Välj betalningsperiod
                  </option>
                  <option
                    :value="item"
                    v-for="(item, i) in subscriptions.payment_plans"
                    :key="i"
                  >
                    {{ item.swe_name }}
                  </option>
                </select>
              </div> -->
              <!--<div class="custom-hidden md:block relative w-1/4">
                <div class="absolute">
                  <img src="./../../assets/golfigo/save.png" alt="" />
                </div>
              </div>-->
              <div class="md:w-1/2"></div>
            </div>
            <div class="mt-2 mb-2 flex flex-col md:flex-row gap-4 p-1">
              <div class="flex flex-col">
                <h6>{{ $t('Home.priceInformationText') }}</h6>
                <!-- Additional text when the notification button is to be shown -->
                <p v-if="notificationPrograms.includes(subForm.accounting_programs.be_name)"
                class="text-green-600">
                  {{ $t('Home.launchInfo') }}
                </p>
                <br>
                <h4 v-if="installationNeededPrograms.includes(subForm.accounting_programs.be_name)"
                  class="text-blue-600">
                    {{ $t('Home.installationCost') }}
                </h4>
                <!-- Download Button -->
                <a v-if="installationNeededPrograms.includes(subForm.accounting_programs.be_name)"
                  href="/exports/VismaAdminInstallation.pdf"
                  class="flex md:w-1/2 p-1 text-center focus:outline-none bg-theme-teal text-white whitespace-nowrap rounded-md border:blue hover:-translate-y-1 transition-transform"
                  download>
                    {{ $t('Home.downloadInstallationGuide') }}
                </a>
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-4 gap-5 px-2 mb-5">
              <div
                v-for="(item, i) in subscriptions.packages"
                :key="i"
                :class="
                  item.swe_name === 'MEDIUM'
                    ? 'bg-blue-800'
                    : item.swe_name === 'ENTERPRISE'
                    ? 'bg-sub-brown'
                    : ''
                "
                class="flex flex-col justify-between rounded-md custom-border p-2 shadow-md"
              >
                <div class="flex flex-col mb-2">
                  <h3
                    :class="
                      item.swe_name === 'MEDIUM'
                        ? 'text-white'
                        : 'text-gray-900'
                    "
                    class="text-4xl font-semibold"
                  >
                    {{ item.swe_name }}
                  </h3>
                  <p
                    :class="
                      item.swe_name === 'MEDIUM'
                        ? 'text-gray-200'
                        : 'text-gray-400'
                    "
                    class="text-lg"
                  ></p>
                </div>
                <div class="flex mb-3">
                  <span class="flex space-x-2 items-center">
                    <span
                      class="text-4xl"
                      :class="
                        item.swe_name === 'MEDIUM'
                          ? 'text-white'
                          : 'text-gray-900'
                      "
                      >{{ item.monthly_price }} SEK</span
                    >
                    <span
                      class="text-xl"
                      :class="
                        item.swe_name === 'MEDIUM'
                          ? 'text-white'
                          : 'text-gray-400'
                      "
                      >exkl. moms / månad</span
                    >
                  </span>
                </div>
                <div class="flex mb-3">
                  <!-- For Notification Button -->
                  <button
                    v-if="notificationPrograms.includes(subForm.accounting_programs.be_name)"
                    @click="showThePopup"
                    :class="item.swe_name === 'MEDIUM' ? 'bg-white text-theme-sidebar' : 'hover:bg-theme-sidebar hover:text-white'"
                    class="custom-border rounded text-center text-lg p-1 border-blue-800 w-full"
                  >
                    {{ $t('Home.notificationSignUp.button') }}
                  </button>
                  
                  <!-- For Kom igång! Button -->
                  <button
                    v-else
                    @click="getStartedSubscription(item.swe_name)"
                    :class="item.swe_name === 'MEDIUM' ? 'bg-white text-theme-sidebar' : 'hover:bg-theme-sidebar hover:text-white'"
                    class="custom-border rounded text-center text-lg p-1 border-blue-800 w-full"
                  >
                    Kom igång!
                  </button>
                </div>
                <!-- Add the SubscribeModal component here -->
                <SubscribeModal :isVisible="showPopup" @update:isVisible="showPopup = $event"/>
                <div class="flex flex-col">
                  <div
                    class="flex items-center space-x-3 mb-2"
                    v-for="i in item.features"
                    :key="i"
                  >
                    <span>
                      <span
                        class="block flex justify-center items-center h-8 w-8 rounded-full bg-gray-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-5 h-5 text-blue-800"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      </span>
                    </span>
                    <span
                      class="text-sm"
                      :class="
                        item.swe_name === 'MEDIUM'
                          ? 'text-gray-300'
                          : item.swe_name === 'ENTERPRISE'
                          ? 'text-gray-800'
                          : 'text-gray-400'
                      "
                      >{{ i }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from "./components/Navbar.vue";
import FooterComponent from "./components/Footer.vue";
import FaqRenderVue from "./custom/AccordionFaq.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SubscribeModal from '@/@core/customComponent/SubscribeModal.vue';

export default {
  name: "Landing",
  components: { NavbarComponent, FooterComponent, FaqRenderVue, SubscribeModal},
  data() {
    return {
      subscriptions: {
        accounting_programs: [],
        packages: [],
        payment_plans: [],
      },
      copyPackages: [],
      faq: { results: [] },
      screenshots: {},
      subForm: {
        accounting_programs: "",
        payment_plans: "",
      },
      cards: [1, 2, 3, 4, 5, 6, 7, 8],
      showPopup: false, 
      // notificationPrograms: ['visma_admin', 'Visma Admin'], 
      notificationPrograms: [], 
      installationNeededPrograms: ['visma_admin', 'Visma Admin']
    };
  },
  mounted() {
    this.getLandingPackages();
    this.getLandingFaq();
    this.getLandingScreenshot();
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    showThePopup() {
        this.showPopup = true;
    },
    getLandingPackages() {
      this.$useJwt.getLandingPackages().then((res) => {
        if (res.status === 200) {
          this.subscriptions = {
            ...res.data.data,
            packages: res.data.data.packages.sort(
              (a, b) =>
                parseFloat(a.monthly_price) - parseFloat(b.monthly_price)
            ),
          };
          this.copyPackages = [
            ...res.data.data.packages.sort(
              (a, b) =>
                parseFloat(a.monthly_price) - parseFloat(b.monthly_price)
            ),
          ];
        }
      });
    },
    getLandingFaq() {
      this.$useJwt.customLanding({ URL: "/faq", method: "get" }).then((res) => {
        if (res.status === 200) {
          this.faq = res.data;
        }
      });
    },
    getLandingScreenshot() {
      this.$useJwt
        .customLanding({ URL: "/dashboard_screenshots", method: "get" })
        .then((res) => {
          if (res.status === 200) {
            this.screenshots = res.data.results;
          }
        });
    },
    getStartedSubscription(pack) {
      if (this.subForm.payment_plans === '') {
        this.subForm.payment_plans = 'Årsvis';
      }
      if (this.subForm.accounting_programs === "") {
        this.popup(
          "Bokföringsprogram är obligatoriskt",
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return;
      } else if (this.subForm.payment_plans === "") {
        this.popup(
          "Betalningsperiod är obligatoriskt",
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return;
      } else {
        this.subForm.package = pack;
        localStorage.setItem("__getStarted__", JSON.stringify(this.subForm));
        this.$router.push({ name: "register" });
      }
    },
    onChangeAccountingProgram(_) {
      this.calculateSubscription();
    },
    onChangePaymentplan(_) {
      this.calculateSubscription();
    },
    calculateSubscription() {
      // const plan = this.subForm.payment_plans
      //   ? this.subForm.payment_plans.monthly_add_on
      //   : 0;
      let plan = 0;
      const paymentPlanObj = this.subscriptions.payment_plans.find(plan => plan.be_name === this.subForm.payment_plans);

      if (paymentPlanObj) {
          plan = paymentPlanObj.monthly_add_on;
      }
      const price = this.subForm.accounting_programs
        ? this.subForm.accounting_programs.monthly_add_on
        : 0;
      const packages = this.copyPackages.map((el) => {
        return {
          ...el,
          monthly_price: parseFloat(el.monthly_price + price + plan),
        };
      });
      this.subscriptions = {
        ...this.subscriptions,
        packages: packages,
      };
    },
    popup(message, variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: message,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.support-gradient {
  font-style: normal;
  font-weight: 794;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(266.01deg, #0b0875 12.33%, #00d090 113.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
</style>
